import React from "react";
import logo from "../images/logo.png";
import "../App.css";
import one from "../images/Products/1.png";
import two from "../images/Products/2.png";
import three from "../images/Products/3.png";
import four from "../images/Products/4.png";
import five from "../images/Products/5.png";
import six from "../images/Products/6.png";
import seven from "../images/Products/7.png";
import eight from "../images/Products/8.png";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function Products() {
  return (
    <div class="jumbotron app-header bg-dark">
      <h1 class="display-4 font-weight-bold text-light ml-4 mb-4">
        Industry Leader in Design and Durability
      </h1>
      <p class="text-light lead ml-4 mb-4">
      We meet with our clients, to provide direct Engineering and Project
        Management to support New Product Design, Manufacturing and Quality
        Engineering, Product Development with implementation into Mass
        Production, Comprehensive Project Management. We also provide
        competitive cost quotes through combined business product volume with
        our key suppliers in Asia.
      </p>
      <div class="row">
        <div class="col-sm col-md-4 mb-4 mb-lg-0">
          <img src={one} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          <img src={eight} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>

        <div class="col-sm mb-4 mb-lg-0">
          <img src={three} class="w-100 shadow-1-strong rounded mb-4" alt="" />

          <img src={four} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>

        <div class="col-sm mb-4 mb-lg-0">
          <img src={five} class="w-100 shadow-1-strong rounded mb-4" alt="" />

          <img src={seven} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Products;
