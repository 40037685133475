import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function Footer() {
  return (
    <footer class="page-footer font-small blue pt-4">

 

  <div class="footer-copyright text-center py-3">© 2021 Copyright:
    <a href="http://d3-tech-partners.com"> D3 Technical Partners</a>
  </div>

</footer>
  );
}

export default Footer;
