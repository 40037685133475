import React from "react";
import logo from "../images/logo.png";
import "../App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ContactInfo from '../components/Contact-Info'
function HomeContact() {
  return (
    <div class="jumbotron app-header bg-info">
      <h2 class="display-5 text-light font-weight-bold ml-4">Make an Inquiry</h2>
      <hr class="my-4 text-light dark-hr" />
      <p class="display-5 text-light">
        For for information about D3 Technical Partners, please feel free to get in contact with us. We will be happy to respond during normal business hours.
      </p>
      <Link to="/contact">
          <div className="btn btn-lg btn-light text-dark">
              <strong>Get In Contact</strong>
          </div>
      </Link>
    </div>
  );
}

export default HomeContact;
