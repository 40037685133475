import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function ProductFocus() {
  return (
    <div class="jumbotron app-header bg-dark">
      <h2 class="display-5 text-light font-weight-bold ml-4">Product Focus</h2>
      <hr class="my-4 text-light dark-hr" />
      <ul class="list-group list-group-flush">
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Operator and Office Headsets</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Heavy Duty Warehouse Headsets</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Point of Sale Component Replacement Assembles</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Consumer Electronics and Accessories</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Industrial Digital Controller PCBA’s</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Cable and Harness Assemblies</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Emergency Vehicle = OEM Cables, Lights, and Brackets</li>
      </ul>
    </div>
  );
}

export default ProductFocus;
