import React from "react";
import Hero from "../components/Hero";
import Construction from "../components/Construction";
import Footer from "../components/Footer";
import HomeContact from "../components/Home-Contact";
import "../App.css";
import NavBar from "../components/NavBar";
import Products from "../components/Products";
import Model from "../components/Model"
import Clients from "../components/Clients";
function Home() {
  return (
    <div class="App-Container">
      <NavBar />
      <Hero />
      <Products/>
      {/* <Clients/> */}
      <HomeContact />
      {/* <Construction /> */}
      <Footer />
    </div>
  );
}

export default Home;
