import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function ProdSource() {
  return (
    <div class="jumbotron app-header bg-light mt-3">
      <h2 class="display-5 font-weight-bold ml-4">Product Sourcing</h2>
      <hr class="my-4" />
      <p class="display-5 font-weight-bold">
      Supplier Selection, Qualification, and Quality Audits based on client requirements.
      </p>
      <p class="display-5 font-weight-bold mt-5">
      Product Testing and Qualification
      </p>
      <ul class="list-group list-group-flush">
        <li class="list-group-item bg-light text-dark display-6 ">
        Develop product specifications, test plans and methods.
        </li>
        <li class="list-group-item bg-light text-dark display-6 ">
        Full Design Verification and Reliability Testing.
        </li>
      </ul>
      <p class="display-5 font-weight-bold mt-5">
      Competitive Quotes based combined business product volume
      </p>
      <p class="display-5 font-weight-bold mt-5">
      On site Engineering and Quality Resources at our China Suppliers
      </p>
      <p class="display-5 font-weight-bold mt-5">
      Product Cost Reduction through Design, Mfg. and Quality Engineering
      </p>
    </div>
  );
}

export default ProdSource;
