import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function ContactInfo() {
  return (
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="card bg-light">
          <h2 class="display-5 ml-4 mt-3">
              Contact Information
            </h2>
            <span class=" text-dark ml-4 display-5">Telephone:</span>
            <h2 class="display-5 ml-4 mt-3">
              +1-844-ModCom1 <br/>(1-844-663-2661)
            </h2>
            <p class="display-5 text-dark ml-4 mt-3">Email: </p>
            <a
              class="display-5 text-primary font-weight-bold"
              href={`mailto:Sales@Tridentcomtech.com`}
            >
              <span class="text-primary ml-4">Sales@Tridentcomtech.com</span>
            </a>
            <a href={`mailto:Service@Tridentcomtech.com`}>
              <p class="display-5 text-primary ml-4 font-weight-bold">
                Service@Tridentcomtech.com
              </p>
            </a>
          </div>
        </div>
        <div class="col">
          <div class="card bg-light mb-4">
            <h2 class="display-5 ml-4 mt-3">
              Fill in the form below to send us a message!
            </h2>
            <form>
              <div class="form-group w-75 ml-4">
                <label for="exampleInputEmail1">Name*</label>
                <input
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="e.g John Doe"
                />
              </div>
              <div class="form-group w-75 ml-4">
                <label for="exampleInputEmail1">Email address*</label>
                <input
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="e.g hello@d3-com-tech.com"
                />
                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div class="form-group w-75 ml-4">
                <label for="exampleInputEmail1">Phone</label>
                <input
                  type="phone"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="e.g +1 (844) 663-2661"
                />
              </div>
              <div class="form-group w-75 ml-4">
                <label for="exampleInputEmail1">Message*</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
              </div>
              <button type="submit" class="btn btn-primary ml-4 mb-4">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
