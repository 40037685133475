import React from "react";
import logo from "../images/logo.png";
import "../App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function Hero() {
  return (
    <div class="jumbotron app-header bg-light">
      <div class="row">
        <img src={logo} className="Header-Logo" alt="Responsive image" />
        <h1 class="display-4 font-weight-bold ml-4">D3 Technical Partners</h1>
      </div>
      <p class="lead">
        Design, Development, Distribution
      </p>
      <hr class="my-4" />
      <p>
      At D3, we focus on the representation of clients who wish to have their products developed and manufactured in China. We provide value added Engineering and Operations support as an extension of our Client’s capabilities… Integrated within their in-house product teams. 
      </p>
      <Link to="/about">
          <div className="btn btn-lg btn-dark text-white">
              <strong>Learn More</strong>
          </div>
      </Link>
    </div>
  );
}

export default Hero;
