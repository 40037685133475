import React from "react";
import logo from "../images/logo.png";
import WhatWeDo from "../components/WhatWeDo";
import ProductFocus from "../components/ProductFocus";
import Footer from "../components/Footer";
import NewProdDev from "../components/NewProdDev";
import TechExp from "../components/TechExp";
import ProdSource from "../components/ProdSource";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ContactInfo from "../components/Contact-Info";
import NavBar from "../components/NavBar";

function Contact() {
  return (
    <div class="App-Container">
      <NavBar />
      <div class="d-flex flex-row justify-content-between">
      <h1 class="display-2 text-info font-weight-bold ml-5 mt-3 text-left">
          Contact <span class="text-dark">D3-Tech</span>
        </h1>
        <div class="position-sticky mr-3 mt-3">
          <Link to="/">
          <img src={logo} className="Header-Logo mt-3 mr-4 d-none d-lg-block" alt="Responsive image" />
          </Link>
        </div>
      </div>
      <div class="card w-75 align-self-center app-header bg-light mb-4">
        <p class="display-5 px-5 text-info font-weight-bold mt-3 align-self-center">
          Our Sales and Customer Service Team is available to take your call.
        </p>
      </div>
      <ContactInfo />
      <Footer />
    </div>
  );
}

export default Contact;
