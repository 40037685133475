import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function WhatWeDo() {
  return (
    <div class="jumbotron app-header bg-light mt-3">
      <h2 class="display-5 font-weight-bold ml-4">What We Do</h2>
      <hr class="my-4" />
      <p>
        At D3, we focus on the representation of clients who wish to have their
        products developed and manufactured in Asia. We provide value added
        Engineering and Operations support as an extension of our Client’s
        capabilities… Integrated within their in-house product teams.
      </p>
      <p>
        We meet with our clients, to provide direct Engineering and Project
        Management to support New Product Design, Manufacturing and Quality
        Engineering, Product Development with implementation into Mass
        Production, Comprehensive Project Management. We also provide
        competitive cost quotes through combined business product volume with
        our key suppliers in China.
      </p>
    </div>
  );
}

export default WhatWeDo;
