import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function NewProdDev() {
  return (
    <div class="jumbotron app-header bg-light mt-3">
      <h2 class="display-5 font-weight-bold ml-4">New Product Development</h2>
      <hr class="my-4" />
      <p class="display-5 font-weight-bold">
        Product Design, Manufacturing and Quality Engineering support to ensure
        product quality and reliability are an integral part of the product
        development process.
      </p>
      <ul class="list-group list-group-flush">
        <li class="list-group-item bg-light text-dark display-6 ">
          Concept Design development, review, approval
        </li>
        <li class="list-group-item bg-light text-dark display-6 ">
          Product Prototypes
        </li>
        <li class="list-group-item bg-light text-dark display-6 ">
          Tooling and Test Development
        </li>
        <li class="list-group-item bg-light text-dark display-6 ">
          Alpha, Beta, Pilot, Mass Production stages
        </li>
        <li class="list-group-item bg-light text-dark display-6 ">
          Full product documentation is the client’s intellectual property
        </li>
      </ul>
      <p class="display-5 font-weight-bold mt-5">
        Comprehensive Project Management driving the creation of a quality
        product, on time, and to budget.
      </p>
      <ul class="list-group list-group-flush">
        <li class="list-group-item bg-light text-dark display-6 ">
          Weekly face to face project planning and weekly status reports
        </li>
        <li class="list-group-item bg-light text-dark display-6 ">
          Detailed Phase Reviews
        </li>
      </ul>
    </div>
  );
}

export default NewProdDev;
