import React from "react";
import logo from "../images/logo.png";
import "../App.css";
function TechExp() {
  return (
    <div class="jumbotron app-header bg-dark">
      <h2 class="display-5 text-light font-weight-bold ml-4">Technical Experience</h2>
      <hr class="my-4 text-light dark-hr" />
      <ul class="list-group list-group-flush">
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Dedicated Supplier product development support teams</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">OEM/ODM – Electronic and Mechanical Design Capabilities</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Design for Reliability / Manufacture / Test</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Product Level Industrial Design</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Functional Product Prototyping</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Plastic Tooling Design and Molding</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Metal Stamping & Forming</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Advanced Product Testing and Qualification – Design Verification Testing</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Acoustic Product Testing</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">Full Agency Testing and Certification</li>
        <li class="list-group-item bg-dark text-light display-6 font-weight-bold">FCC, CE, C-Tick, RoHS, WEEE, REACH, IP-67</li>
      </ul>
    </div>
  );
}

export default TechExp;
