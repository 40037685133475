import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import Home from './screens/Home';
import About from './screens/About';
import Contact from './screens/Contact'
import './App.css'

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/about", name: "About", Component: About },
  { path: "/Contact", name: "Contact", Component: Contact },
];

export default function App() {
  return (
    <Router>
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={{ enter: 300, exit: 300 }} 
              classNames="page"
              unmountOnExit
            >
              <div className="page">
                <Component />
              </div>
            </CSSTransition>
          )}
        </Route>
      ))}
    </Router>
  );
}
