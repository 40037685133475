import React from "react";
import logo from "../images/logo.png";
import WhatWeDo from "../components/WhatWeDo";
import ProductFocus from "../components/ProductFocus";
import Footer from "../components/Footer";
import NewProdDev from "../components/NewProdDev";
import TechExp from "../components/TechExp";
import ProdSource from "../components/ProdSource";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import NavBar from "../components/NavBar";

function About() {
  return (
    <div class="App-Container">
      <NavBar/>
      <div class="d-flex flex-row justify-content-between">
        <h1 class="display-2 text-warning font-weight-bold ml-5 mt-3 text-left">
          About <span class="text-dark">D3-Tech</span>
        </h1>
        <div class="btn position-sticky mr-3 mt-3">
          <Link to="/">
          <img src={logo} className="Header-Logo mt-3 mr-4 d-none d-lg-block" alt="Responsive image" />
          </Link>
        </div>
      </div>

      <WhatWeDo />
      <ProductFocus />
      <NewProdDev />
      <TechExp />
      <ProdSource />
      <Footer />
    </div>
  );
}

export default About;
